<template>
	<div class="v-slider">
		<div class="input">
			<div ref="track" class="track-wrapper">
				<div
					class="track-fill"
					:style="{
						width: `${progress * 100}%`,
						transform: `translateX(${fromProgress * 100}%)`,
					}"
				/>
			</div>
			<input
				:id="`${id}-to`"
				:name="`${id}-to`"
				class="input-to"
				v-model="toValue"
				:min="min"
				:max="max"
				:step="step"
				type="range"
			/>
			<input
				v-if="type === 'array'"
				:id="`${id}-from`"
				:name="`${id}-from`"
				class="input-from"
				v-model="fromValue"
				:min="min"
				:max="max"
				:step="step"
				type="range"
			/>
			<div class="output-container">
				<div
					:style="{
						transform: `translateX(${
							((toValue - min) / (max - min)) * 100
						}%)`,
					}"
				>
					<output
						:for="`${id}-to`"
						:class="{
							'output-shown': alwaysShowOutput,
						}"
					>
						{{ prefix }} {{ toValue }} {{ unit }}
					</output>
				</div>
				<div
					v-if="type === 'array'"
					:style="{
						transform: `translateX(${
							((fromValue - min) / (max - min)) * 100
						}%)`,
					}"
				>
					<output
						:for="`${id}-to`"
						:class="{
							'output-shown': alwaysShowOutput,
						}"
					>
						{{ prefix }} {{ fromValue }} {{ unit }}
					</output>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "VSlider",
	props: {
		id: {
			type: String,
			default: null,
		},
		value: {
			type: Number | Array,
			default: null,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 100,
		},
		step: {
			type: [Number, String],
			default: 1,
		},
		unit: {
			type: String,
			default: null,
		},
		alwaysShowOutput: {
			type: Boolean,
			default: false,
		},
		prefix: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: "integer",
		},
	},
	data() {
		return {
			toValue: null,
			fromValue: null,
		};
	},
	created() {
		if (this.value) {
			if (Array.isArray(this.value)) {
				[this.fromValue, this.toValue] = this.value;
			} else {
				this.toValue = this.value;
			}
		} else {
			this.toValue = (Number(this.max) - Number(this.min)) / 2;
		}

		this.fromValue = Number(this.min);
	},
	computed: {
		maxValue() {
			return Math.max(this.fromValue, this.toValue);
		},
		minValue() {
			return Math.min(this.fromValue, this.toValue);
		},
		fromProgress() {
			return (
				(this.minValue - Number(this.min)) /
				(this.maxValue - this.minValue)
			);
		},
		progress() {
			return (
				(this.maxValue - this.minValue) /
				(Number(this.max) - Number(this.min))
			);
		},
		emitValue() {
			if (this.type === "integer") {
				return this.toValue;
			} else {
				return [Number(this.minValue), Number(this.maxValue)];
			}
		},
	},
	watch: {
		emitValue() {
			this.$emit("input", this.emitValue);
		},
	},
};
</script>

<style lang="scss" scoped>
.v-slider {
	position: relative;

	.input {
		transition: border-color var(--fast) var(--transition);
		padding: 12px 0 0;
		border-radius: 2px;

		flex-grow: 1;
		flex-shrink: 0;
		display: grid;
		grid-template-columns: 2;
		grid-template-rows: 1;
		grid-gap: 10px;

		&:hover {
			transition: none;
			border-color: var(--blue-grey-300);
		}
	}

	.track-wrapper {
		z-index: 0;
		height: 4px;
		width: 100%;
		border-radius: 2px;
		pointer-events: none;
		user-select: none;
		position: relative;
		background-color: var(--input-border-color);

		.track-fill {
			position: absolute;
			background-color: var(--input-text-color);
			border-radius: 2px;
			width: 100%;
			height: 100%;
			left: 0;
			width: 0;
		}
	}

	input,
	.track-wrapper {
		grid-row: 1;
		grid-column: 1;
	}

	input {
		pointer-events: none;
		appearance: none;
		-webkit-appearance: none;
		width: 100%;
		height: 4px;
		background-color: transparent;
		z-index: 1;

		&::-webkit-slider-runnable-track {
			height: 4px;
			background: var(--input-border-color);
			box-shadow: none;
			border: none;
			border-radius: 2px;
		}

		&::-moz-range-thumb,
		&::-webkit-slider-thumb {
			pointer-events: all;
			appearance: none;
			-webkit-appearance: none;
			box-shadow: none;
			border: none;
			height: 14px;
			width: 14px;
			border-radius: 50%;
			background: var(--input-text-color);
			margin-top: -6px;
			cursor: ew-resize;
			position: relative;
		}
	}

	.output-container {
		position: relative;
		margin-right: 14px;
		margin-top: 2px;
		height: 0;
		pointer-events: none;
	}

	output {
		transition: opacity var(--fast) var(--transition);
		position: absolute;
		display: inline-block;
		white-space: nowrap;
		top: 4px;
		margin-left: 7px;
		text-align: center;
		transform: translateX(-50%);
		color: var(--input-text-color);
		background-color: var(--input-background-color-alt);
		border-radius: var(--border-radius);
		font-size: var(--input-font-size);
		padding: 4px 8px;
		opacity: 0;
		user-select: none;
		z-index: 4;

		&:before {
			content: "";
			position: absolute;
			top: -4px;
			left: calc(50%);
			width: 10px;
			height: 10px;
			border-radius: var(--border-radius);
			transform: translateX(-50%) rotate(45deg);
			background-color: var(--input-background-color-alt);
		}

		&.output-shown {
			opacity: 1;
		}
	}

	.input:hover output,
	.input:active output,
	.user-is-tabbing .input:focus output,
	.user-is-tabbing .input:focus-within output {
		opacity: 1;
		transition: var(--fast) var(--transition-in);
	}

	.input:hover output,
	.input:active output,
	.user-is-tabbing .input:focus output,
	.user-is-tabbing .input:focus-within output {
		opacity: 1;
		transition: var(--fast) var(--transition-in);
	}
}
</style>
