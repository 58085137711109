var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-slider"},[_c('div',{staticClass:"input"},[_c('div',{ref:"track",staticClass:"track-wrapper"},[_c('div',{staticClass:"track-fill",style:({
					width: `${_vm.progress * 100}%`,
					transform: `translateX(${_vm.fromProgress * 100}%)`,
				})})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.toValue),expression:"toValue"}],staticClass:"input-to",attrs:{"id":`${_vm.id}-to`,"name":`${_vm.id}-to`,"min":_vm.min,"max":_vm.max,"step":_vm.step,"type":"range"},domProps:{"value":(_vm.toValue)},on:{"__r":function($event){_vm.toValue=$event.target.value}}}),(_vm.type === 'array')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fromValue),expression:"fromValue"}],staticClass:"input-from",attrs:{"id":`${_vm.id}-from`,"name":`${_vm.id}-from`,"min":_vm.min,"max":_vm.max,"step":_vm.step,"type":"range"},domProps:{"value":(_vm.fromValue)},on:{"__r":function($event){_vm.fromValue=$event.target.value}}}):_vm._e(),_c('div',{staticClass:"output-container"},[_c('div',{style:({
					transform: `translateX(${
						((_vm.toValue - _vm.min) / (_vm.max - _vm.min)) * 100
					}%)`,
				})},[_c('output',{class:{
						'output-shown': _vm.alwaysShowOutput,
					},attrs:{"for":`${_vm.id}-to`}},[_vm._v(" "+_vm._s(_vm.prefix)+" "+_vm._s(_vm.toValue)+" "+_vm._s(_vm.unit)+" ")])]),(_vm.type === 'array')?_c('div',{style:({
					transform: `translateX(${
						((_vm.fromValue - _vm.min) / (_vm.max - _vm.min)) * 100
					}%)`,
				})},[_c('output',{class:{
						'output-shown': _vm.alwaysShowOutput,
					},attrs:{"for":`${_vm.id}-to`}},[_vm._v(" "+_vm._s(_vm.prefix)+" "+_vm._s(_vm.fromValue)+" "+_vm._s(_vm.unit)+" ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }