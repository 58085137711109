export function signalStrength(state) {
	// eslint-disable-line
	let lastLatency = state.latency[state.latency.length - 1];

	if (!lastLatency) {
		return 0;
	}

	lastLatency = lastLatency.latency;

	if (lastLatency > 0 && lastLatency < 200) {
		return 4;
	}

	if (lastLatency >= 200 && lastLatency < 500) {
		return 3;
	}

	if (lastLatency >= 500 && lastLatency < 1000) {
		return 2;
	}

	if (lastLatency >= 1000) {
		return 1;
	}

	return 0;
}

export function currentProject(state) {
	if (!state.projects) return null;
	return state.projects.find(project => {
		return project.key === state.currentProjectKey;
	});
}

function removePathPrefix(path, prefix = "") {
    if (!path) return null;

    const re = new RegExp(`^${prefix}`, "g");
    return path.replace(re, '');
}

export const currentPage = (state) => (routePath) => {
    return removePathPrefix(routePath, `/${state.currentProjectKey}`)
}

export function lastRoute(state) {
    const lastPage = state.currentUser.last_page;
    if (!lastPage) return null;

    const prefix = `/${state.currentProjectKey}`;
    return `${prefix}${removePathPrefix(lastPage, prefix)}`
}
