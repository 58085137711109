<template>
	<vue-select :components="{ Deselect, OpenIndicator }" :class="{ icon }" :id="otherActive ? null : id"
		:clearable="clearable" :disabled="disabled || readonly" :options="parsedOptions" :placeholder="placeholder"
		:value="parsedValue" :reduce="(option) => option.value" :selectable="(option) => !option.disabled"
		:taggable="parsedValue === customValue" :create-option="createOption" :no-drop="noDrop" :multiple="multiple"
		@input="change($event)" @open="$emit('open')" />
</template>

<script>
import VIcon from '../../components/v-icon/';
export default {
	name: 'VSelect',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number, Array],
			default: '',
		},

		other: {
			type: Boolean,
			default: false,
		},
		icon: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		options: {
			type: [Object, String, Array],
			required: true,
		},
		placeholder: {
			type: String,
			default: 'Choose one...',
		},

		defaultValue: {
			type: Boolean,
			default: false,
		},
		noDrop: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			otherActive: false,
			customValue: '',
			Deselect: { render: (h) => h(VIcon, { props: { name: 'close', small: true } }) },
			OpenIndicator: { render: (h) => h(VIcon, { props: { name: 'keyboard_arrow_down' } }) },
		};
	},
	computed: {
		parsedValue() {
			if (this.value && !Array.isArray(this.value)) return `${this.value}`;

			return this.value;
		},
		parsedOptions() {
			if (typeof this.options === 'string') {
				return this.getOptions(JSON.parse(this.options));
			}

			return this.getOptions(this.options);
		},
	},
	methods: {
		getOptions(options) {
			let result = Object.entries(options).map(
				([key, value]) => ({
					value: key,
					label: value,
				}));

			if (this.other) {
				const values = [{ label: this.$t('values'), value: '', disabled: true }, ...result];
				const otherValues = [
					{ label: this.$t('other'), value: '', disabled: true },
					{
						label: this.customValue.length ? this.customValue : this.$t('enter_value'),
						value: this.customValue,
					},
				];
				result = [...values, ...otherValues];
			}

			return result;
		},
		createOption(value) {
			const newOption = { label: value, value };
			this.$emit('option:created', newOption);
			return newOption;
		},
		change(value) {
			if (value === this.customValue || value === '__other') {
				this.$emit('input', this.customValue);
				this.otherActive = true;
				return;
			}

			this.otherActive = false;
			this.$emit('input', value);

			if (this.defaultValue === true) {
				this.$refs.default.setAttribute('selected', 'selected');
				this.$refs.select.value = '';
			}
		},
		changeCustom(event) {
			this.customValue = event.target.value;
			this.$emit('input', this.customValue);
		},
	},
};
</script>

<style lang="scss" scoped>
:deep() {
	// font
	--vs-font-size: var(--input-font-size);
	--vs-line-height: var(--line-height);
	--vs-disabled-bg: var(--input-background-color-disabled);

	--vs-border-color: var(--input-border-color);
	--vs-border-width: var(--input-border-width);
	--vs-border-radius: var(--border-radius);
	--vs-search-input-placeholder-color: var(--input-placeholder-color);
	--vs-search-input-bg: var(--input-background-color);
	--vs-dropdown-bg: var(--input-background-color);
	// options
	--vs-dropdown-option-padding: var(--input-padding);
	// active
	--vs-selected-bg: var(--highlight);
	--vs-selected-color: var(--input-text-color);
	--vs-dropdown-option--active-bg: var(--input-background-color-alt-hover);
	--vs-dropdown-option--active-color: var(--input-text-color);
	// actions
	--vs-actions-padding: 0;
	// Transitions
	--vs-transition-timing-function: var(--transition);
	--vs-transition-duration: var(--fast);
	// dropdown:
	--vs-dropdown-min-width: auto;
	// vs-state
	--vs-state-disabled-bg: var(--input-background-color-disabled);
	--vs-state-disabled-color: var(--input-text-color);
}

.vs--single :deep,
.vs--multiple :deep {
	.vs__dropdown-toggle {
		align-items: center;
		height: var(--input-height) !important;
		padding: var(--input-padding);
		// min-width: 7em;
	}
}

.vs--single :deep {

	.vs__selected,
	.vs__selected-options,
	.vs__search,
	.vs__search:focus {
		margin: 0;
		padding: 0;
	}

	.vs__selected-options {
		flex-wrap: nowrap;
		overflow: hidden;
	}

	.vs__selected {
		display: block;
		min-width: 0;
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.vs__clear {
		color: var(--danger);

		&:hover {
			color: var(--danger-dark);
		}
	}
}
</style>
