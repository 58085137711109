import { format as fnsFormat } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const defaultDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
const ISO8601FormatRegex = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/;

/**
 * Get locale timezone from browser
 * @returns {String}
 */
function getLocaleTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Convert SQL datetime string to JavaScript Date object
 * @param  {String} sqlDate  SQL date-time string "YYYY-MM-DD HH:MM:SS" or date "YYYY-MM-DD" or UTC datetime "YYYY-MM-DDTHH:MM:SS+00:00"
 * @param  {String} timezone User timezone, default is follow the browser's timezone
 * @return {Date}			 JavaScript Date object
 */
function sqlToDate(sqlDate, timezone) {
	if (!sqlDate) return null;

	const date = new Date(sqlDate);
	const isISO8601String = ISO8601FormatRegex.test(sqlDate);
	const utcDate = isISO8601String
		? date
		: new Date(
				Date.UTC(
					date.getFullYear(),
					date.getMonth(),
					date.getDate(),
					date.getHours(),
					date.getMinutes(),
					date.getSeconds(),
					date.getMilliseconds()
				)
		  );

	if (!timezone) {
		timezone = getLocaleTimezone();
	}

	return utcToZonedTime(utcDate, timezone);
}

/**
 * Convert JS Date object to SQL datetime string
 * @param  {Date} 	date JavaScript Date date
 * @param  {String} timezone User timezone, default is follow the browser's timezone
 * @return {String} SQL datetime string YYYY-MM-DD HH:MM:SS
 */
function dateToSql(date, timezone) {
	if (!date instanceof Date) return null;

	const localDateString = format(date, defaultDateTimeFormat);

	if (!timezone) timezone = getLocaleTimezone();
	date = zonedTimeToUtc(localDateString, timezone);

	return date.toISOString();
}

function format(date, dateFormat) {
	if (!dateFormat) dateFormat = defaultDateTimeFormat;

	return fnsFormat(date, dateFormat);
}

export default {
	defaultDateTimeFormat,
	format,
	getLocaleTimezone,
	sqlToDate,
	dateToSql
};
