var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-blocker',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"blocker-info",attrs:{"z-index":9},on:{"click":function($event){return _vm.toggle(false)}}}),_c('aside',{staticClass:"info-sidebar",class:{ active: _vm.active }},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
				content: _vm.$t('info'),
				boundariesElement: 'body'
			}),expression:"{\n\t\t\t\tcontent: $t('info'),\n\t\t\t\tboundariesElement: 'body'\n\t\t\t}",modifiers:{"left":true}}],staticClass:"sidebar-button",on:{"click":function($event){return _vm.toggle(!_vm.active)}}},[_c('v-icon',{attrs:{"icon-style":"outline","name":"info","color":"--sidebar-text-color"}}),(_vm.active)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('info')))]):_vm._e()],1),(_vm.active)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"system"},[_vm._t("system")],2),_vm._t("default")],2):_vm._e(),(_vm.canReadActivity)?_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
				content: _vm.$t('notifications'),
				boundariesElement: 'body'
			}),expression:"{\n\t\t\t\tcontent: $t('notifications'),\n\t\t\t\tboundariesElement: 'body'\n\t\t\t}",modifiers:{"left":true}}],staticClass:"notifications sidebar-button",attrs:{"to":`/${_vm.currentProjectKey}/activity`}},[_c('v-icon',{attrs:{"name":"notifications","color":"--sidebar-text-color"}}),(_vm.active)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('notifications')))]):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }