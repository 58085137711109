var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
		delay: { show: 750, hide: 100 },
		content: _vm.label
	}),expression:"{\n\t\tdelay: { show: 750, hide: 100 },\n\t\tcontent: label\n\t}"}],staticClass:"v-header-button"},[(Object.keys(_vm.options).length > 0)?_c('v-contextual-menu',{staticClass:"options",attrs:{"placement":"bottom-end","options":_vm.options},on:{"click":_vm.emitChange}}):_vm._e(),_c(_vm.disabled ? 'button' : _vm.to ? 'router-link' : 'button',{tag:"component",staticClass:"button",class:{ attention: _vm.alert, outline: _vm.outline, 'has-bg': _vm.hoverColor },style:({
			backgroundColor: _vm.outline || _vm.disabled ? null : `var(--${_vm.backgroundColor})`,
			borderColor: `var(--${_vm.backgroundColor})`,
			color: `var(--${_vm.backgroundColor})`,
			'--hover-color': _vm.hoverColor ? `var(--${_vm.hoverColor})` : null
		}),attrs:{"disabled":_vm.disabled || _vm.loading,"to":_vm.to || null},on:{"click":function($event){!_vm.to ? _vm.$emit('click', $event) : null}}},[(_vm.loading)?_c('v-spinner',{attrs:{"size":24,"color":"white","background-color":"transparent"}}):_c('v-icon',{attrs:{"color":`--${_vm.iconColor}`,"name":_vm.icon}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }