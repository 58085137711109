<template>
	<div
		ref="container"
		:style="{ minWidth: totalWidth + 'px' }"
		class="v-table"
		:class="{ loading }"
	>
		<div class="toolbar" :class="{ shadow: scrolled }">
			<div v-if="manualSortField" class="manual-sort cell" :class="{ active: manualSorting }">
				<button v-tooltip="$t('enable_manual_sorting')" @click="startManualSorting">
					<v-icon name="sort" />
				</button>
			</div>
			<div v-if="selectable" class="select cell">
				<v-checkbox
					id="select-all"
					:inputValue="allSelected"
					name="select-all"
					value="all"
					@change="selectAll"
				/>
			</div>
			<div
				v-for="({ field, name }, index) in columns"
				:key="field"
				:style="{
					flexBasis: widths && widths[field] ? widths[field] + 'px' : null,
				}"
				:class="`cell drag-cell-${index}`"
			>
				<button
					v-if="sortable && !isRelational(columns[index].fieldInfo)"
					:class="{ active: sortVal.field === field }"
					class="sort type-table-head no-wrap"
					@click="updateSort(field)"
				>
					{{
						widths[field] > 40
							? $helpers.formatField(field, columns[index].fieldInfo.collection)
							: null
					}}
					<v-icon
						class="sort-icon"
						color="--input-border-color-hover"
						name="sort"
						:class="sortVal.asc ? 'asc' : 'desc'"
					/>
				</button>

				<span
					v-else
					v-tooltip="
						isRelational(columns[index].fieldInfo) ? $t('cant_sort_by_this_field') : undefined
					"
					class="type-table-head"
				>
					{{ widths[field] > 40 ? name : null }}
				</span>

				<div
					v-if="resizeable && index !== columns.length - 1"
					class="drag-handle"
					draggable
					@drag="drag(field, index, $event)"
					@dragstart="hideDragImage(field, $event)"
					@dragend="dragEnd(field, index, $event)"
				>
					<div class="drag-handle-line" />
				</div>
			</div>
		</div>
		<div class="body" :class="{ loading, dragging }">
			<div v-if="loading && items.length === 0" class="loader">
				<div v-for="n in 50" :key="n" class="row" :style="{ padding: `${rowPadding}px 12px` }" />
			</div>
			<component
				:is="manualSorting && !searchQuery ? 'draggable' : 'div'"
				v-model="itemsManuallySorted"
				handle=".manual-sort"
				@start="startSort"
				@end="saveSort"
				@change="changeSort"
			>
				<template v-if="link">
					<div
						v-for="row in tree ? itemsManuallySorted : itemsArray"
						v-show="showDepth(row)"
						:key="row[primaryKeyField]"
						:style="{ padding: `${rowPadding}px 12px` }"
						:class="{
							selected: selection && selection.includes(row[primaryKeyField]),
						}"
						class="link row"
						tabindex="0"
						role="link"
						@click.stop.exact="$router.push(row[link])"
						@click.stop.ctrl="openNew(row)"
						@keyup.enter.stop="$router.push(row[link])"
					>
						<div
							v-if="manualSortField"
							class="manual-sort cell"
							:class="{ active: manualSorting && !searchQuery }"
							@click.stop.prevent
						>
							<v-icon name="drag_handle" />
						</div>
						<div v-if="selectable" class="cell select" @click.stop>
							<v-checkbox
								:id="'check-' + row[primaryKeyField]"
								:value="`${row[primaryKeyField]}`"
								:inputValue="selection.includes(row[primaryKeyField])"
								:disabled="row._hasChilds && row._hasChilds.length ? true : false"
								@change="toggleCheckbox(row[primaryKeyField])"
							/>
						</div>
						<div
							v-for="({ field, fieldInfo }, index) in columns"
							:key="field"
							:style="{
								flexBasis: widths && widths[field] ? widths[field] + 'px' : null,
							}"
							class="cell"
						>
							<div :class="{ tree }">
								<div
									v-if="tree && index === 0"
									:style="tree ? { 'padding-left': row._depth * 24 + 'px' } : null"
								>
									<v-icon
										v-if="row._hasChilds && row._hasChilds.length"
										class="down-icon"
										:class="{
											'down-icon-active':
												treeStatus[row._maxID] && treeStatus[row._maxID].includes(row.id),
										}"
										name="arrow_right"
										:size="30"
										@click.stop="toggle(row)"
									></v-icon>
									<div v-else class="pl-8"></div>
								</div>
								<div
									v-if="
										(row[field] === '' || isNil(row[field])) &&
										fieldInfo &&
										fieldInfo.type.toLowerCase() !== 'alias'
									"
									class="empty"
								>
									--
								</div>
								<v-ext-display
									v-else-if="
										useInterfaces &&
										(!isNil(row[field]) || (fieldInfo && fieldInfo.type.toLowerCase() === 'alias'))
									"
									:id="field"
									:interface-type="fieldInfo.interface"
									:name="field"
									:values="row"
									:collection="collection"
									:type="fieldInfo.type"
									:datatype="fieldInfo.datatype"
									:options="fieldInfo.options"
									:value="row[field]"
									:relation="fieldInfo.relation"
									class="ellipsis"
								/>
								<template v-else>
									{{ row[field] }}
								</template>
							</div>
						</div>
					</div>
				</template>

				<template v-else>
					<div
						v-for="row in itemsArray"
						v-show="showDepth(row)"
						:key="row[primaryKeyField]"
						:style="{ padding: `${rowPadding}px 12px` }"
						class="row"
					>
						<div v-if="selectable" class="select" @click.stop>
							<v-checkbox
								:id="'check-' + row[primaryKeyField]"
								:value="`${row[primaryKeyField]}`"
								:inputValue="selection.includes(row[primaryKeyField])"
								:disabled="row._hasChilds && row._hasChilds.length ? true : false"
								@change="toggleCheckbox(row[primaryKeyField])"
							/>
						</div>
						<div
							v-for="({ field, fieldInfo }, index) in columns"
							:key="field"
							:style="{
								flexBasis: widths && widths[field] ? widths[field] + 'px' : null,
							}"
							class="cell"
						>
							<div :class="{ tree }">
								<div
									v-if="tree && index === 0"
									:style="tree ? { 'padding-left': row._depth * 24 + 'px' } : null"
								>
									<v-icon
										v-if="row._hasChilds && row._hasChilds.length"
										class="down-icon"
										:class="{
											'down-icon-active':
												treeStatus[row._maxID] && treeStatus[row._maxID].includes(row.id),
										}"
										name="arrow_right"
										:size="30"
										@click.stop="toggle(row)"
									></v-icon>
									<div v-else class="pl-8"></div>
								</div>
								<div v-if="row[field] === '' || isNil(row[field])" class="empty">--</div>
								<v-ext-display
									v-else-if="useInterfaces && !isNil(row[field])"
									:id="field"
									:interface-type="fieldInfo.interface"
									:name="field"
									:collection="collection"
									:type="fieldInfo.type"
									:options="fieldInfo.options"
									:value="row[field]"
								/>
								<template v-else>
									{{ row[field] }}
								</template>
							</div>
						</div>
					</div>
				</template>
			</component>
		</div>
		<transition name="fade">
			<div v-if="lazyLoading" class="lazy-loader">
				<v-spinner color="--blue-grey-300" background-color="--blue-grey-200" />
			</div>
		</transition>
	</div>
</template>

<script>
import isRelational from '@/helpers/is-relational';
import { isObject, isEqual, isNil, cloneDeep } from 'lodash';

export default {
	name: 'VTable',
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		lazyLoading: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			required: true,
		},
		height: {
			type: Number,
			default: null,
		},
		columns: {
			type: Array,
			required: true,
		},
		link: {
			type: String,
			default: null,
		},
		selection: {
			type: Array,
			default: null,
		},
		sortVal: {
			type: Object,
			default: null,
		},
		manualSortField: {
			type: String,
			default: null,
		},
		primaryKeyField: {
			type: String,
			required: true,
		},
		rowHeight: {
			type: Number,
			default: 48,
		},
		rowPadding: {
			type: Number,
			default: 12,
		},
		columnWidths: {
			type: Object,
			default: null,
		},
		useInterfaces: {
			type: Boolean,
			default: false,
		},
		collection: {
			type: String,
			default: null,
		},
		tree: {
			type: Boolean,
			default: false,
		},
		setTreeDepth: {
			type: Function,
			default: null,
		},
		parentField: {
			type: String,
			default: 'parent',
		},
		treeChilds: {
			type: Array,
			default: null,
		},
		searchQuery: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			widths: {},
			lastDragXPosition: null,
			windowHeight: 0,
			scrolled: false,

			dragging: false,
			manualSorting: false,
			itemsManuallySorted: [],
			dragWidths: {},
			dragField: null,
			throttling: false,
			moveElement: {},
			others: [],
			isUpdate: false,
		};
	},
	computed: {
		treeStatus() {
			return this.$store.state.treeStatus || {};
		},
		allSelected() {
			const items = this.tree ? this.itemsManuallySorted : this.itemsArray;
			const primaryKeyFields = items.map((item) => item[this.primaryKeyField]).sort();
			const selection = [...this.selection];
			selection.sort();
			return this.selection.length > 0 && isEqual(primaryKeyFields, selection);
		},
		selectable() {
			return Array.isArray(this.selection);
		},
		sortable() {
			return isObject(this.sortVal);
		},
		resizeable() {
			return isObject(this.columnWidths);
		},
		totalWidth() {
			return (
				Object.keys(this.widths)
					.map((field) => this.widths[field])
					.reduce((acc, val) => acc + val, 0) +
				30 +
				40 +
				(this.manualSorting ? 38 : 0)
			);
		},
		itemsArray() {
			if (this.tree && this.setTreeDepth) {
				return this.setTreeDepth(this.items, this.others);
			}
			return this.manualSorting ? this.itemsManuallySorted : this.items;
		},
	},
	watch: {
		columnWidths() {
			this.initWidths();
		},
		columns() {
			this.initWidths();
		},
		items(newVal) {
			this.isUpdate = false;
			if (!this.tree) this.itemsManuallySorted = newVal;
		},
		itemsArray(newVal) {
			if (this.tree) this.itemsManuallySorted = newVal;
		},
		treeChilds(val) {
			if (val) this.others = [...this.others, ...val];
		},
	},
	created() {
		this.initWidths();

		if (
			!this.manualSortField ||
			!(this.sortVal && this.sortVal.field === this.manualSortField && this.sortVal.asc === true)
		) {
			return;
		}

		this.manualSorting = true;

		if (this.tree) {
			this.itemsManuallySorted = this.itemsArray;
		} else {
			this.itemsManuallySorted = this.items;
		}
	},
	mounted() {
		window.addEventListener('scroll', this.onScroll, { passive: true });
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
	},
	methods: {
		isRelational: isRelational,
		openNew(item) {
			window.open(window.location.href + '/' + item[this.primaryKeyField]);
		},
		isNil(val) {
			return isNil(val);
		},
		selectAll() {
			if (this.allSelected) {
				return this.$emit('select', []);
			}
			const items = this.tree ? this.itemsManuallySorted : this.itemsArray;
			const primaryKeyFields = items.map((item) => item[this.primaryKeyField]);
			return this.$emit('select', primaryKeyFields);
		},
		updateSort(field, direction) {
			this.manualSorting = false;

			if (direction) {
				const newSortVal = {
					field,
					asc: direction === 'asc',
				};
				return this.$emit('sort', newSortVal);
			}

			const newSortVal = {
				field,
				asc: field === this.sortVal.field ? !this.sortVal.asc : 'ASC',
			};

			this.$emit('sort', newSortVal);
		},
		toggleCheckbox(primaryKeyField) {
			const selection = [...this.selection];

			if (this.selection.includes(primaryKeyField)) {
				selection.splice(selection.indexOf(primaryKeyField), 1);
			} else {
				selection.push(primaryKeyField);
			}

			this.$emit('select', selection);
		},
		drag(field, index, event) {
			// is not Firefox return
			if (this.throttling || window.navigator.userAgent.indexOf('Firefox') >= 0) return;

			this.throttling = true;
			const { screenX } = event;
			window.requestAnimationFrame(() => {
				if (screenX !== 0 && this.lastDragXPosition) {
					const delta = screenX - this.lastDragXPosition;
					const newPos = this.dragWidths[field] + delta;
					const elem = document.querySelector(`.drag-cell-${index}`);
					elem.style.flexBasis = newPos + 'px';
					this.dragWidths[field] = newPos;
				}
				this.lastDragXPosition = screenX;
				this.throttling = false;
			});
		},
		dragEnd(field, index, event) {
			if (window.navigator.userAgent.indexOf('Firefox') >= 0) {
				// Firefox
				const { screenX } = event;
				if (screenX !== 0 && this.lastDragXPosition) {
					const delta = screenX - this.lastDragXPosition;
					const newPos = this.dragWidths[field] + delta;
					const elem = document.querySelector(`.drag-cell-${index}`);
					elem.style.flexBasis = newPos + 'px';
					this.dragWidths[field] = newPos;
				}
			}

			this.lastDragXPosition = 0;
			this.widths = cloneDeep(this.dragWidths);
			this.$emit('widths', this.widths);
		},
		hideDragImage(field, event) {
			const { screenX } = event;
			this.lastDragXPosition = screenX;
			this.dragField = field;
			const img = document.createElement('img');
			img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
			event.dataTransfer.setDragImage(img, 0, 0);
			event.dataTransfer.effectAllowed = 'move';
			event.dataTransfer.setData('text/plain', null); //Just to enable/make the drag event to be triggered in the Firefox browser
		},
		initWidths() {
			const widths = {};

			this.columns.forEach(({ field }) => {
				const width = (this.columnWidths && this.columnWidths[field]) || 200;
				widths[field] = width > 0 ? width : 200;
			});

			this.widths = widths;
			this.dragWidths = cloneDeep(this.widths);
		},
		onScroll() {
			const { scrollHeight, clientHeight, scrollTop } = document.documentElement;
			const totalScroll = scrollHeight - clientHeight;
			const delta = totalScroll - scrollTop;
			if (delta <= 500) this.$emit('scroll-end');
			this.scrolled = scrollTop > 0;
		},
		startManualSorting() {
			if (this.manualSorting) {
				this.manualSorting = false;
				return;
			}

			this.updateSort(this.manualSortField, 'asc');
			this.manualSorting = true;
		},
		startSort() {
			this.dragging = true;
		},
		changeSort(event) {
			this.moveElement = event.moved;
		},
		saveSort(e) {
			if (e.newIndex === e.oldIndex) return;

			const prevItem = this.itemsManuallySorted[e.newIndex - 1] || {};
			const nextItem = this.itemsManuallySorted[e.newIndex + 1] || {};

			this.dragging = false;

			if (this.tree) this.updateParent();

			if (this.isUpdate) return;

			let updateItemsSort = [];
			let index = 1;

			const setItemSort = (prev, next) => {
				const rankValue = this.getRankValueBetweenItems(prev, next);
				const item = this.itemsManuallySorted[e.newIndex + index - 1];

				if (rankValue) {
					updateItemsSort.push({
						[this.primaryKeyField]: item[this.primaryKeyField],
						[this.manualSortField]: rankValue ? rankValue.toFixed(8) : null,
					});
				} else {
					let sort = 1;
					updateItemsSort = this.itemsManuallySorted
						.filter((elem, index) => {
							if (index < e.newIndex && elem[this.manualSortField]) {
								sort = elem[this.manualSortField];
							}
							return index === e.newIndex || (index < e.newIndex && !elem[this.manualSortField]);
						})
						.map((elem, index) => {
							return {
								[this.primaryKeyField]: elem[this.primaryKeyField],
								[this.manualSortField]: (sort + index).toFixed(8),
							};
						});
				}

				if (!rankValue || !next) return;

				/* 模糊中间值接近下一个sort值时，更新下一个的sort值，一直递归 */

				const next1 = this.itemsManuallySorted[e.newIndex + index] || {};
				const m = Math.pow(10, 8);

				if (Number(rankValue.toFixed(8)) * m - Number(next1[this.manualSortField]) * m >= -1) {
					const next2 = this.itemsManuallySorted[e.newIndex + index + 1] || {};
					index++;
					setItemSort(Number(rankValue.toFixed(8)), Number(next2[this.manualSortField]));
				}
			};

			setItemSort(prevItem[this.manualSortField], nextItem[this.manualSortField]);

			return this.$emit('input', updateItemsSort);
		},
		updateParent() {
			const newIndex = this.moveElement.newIndex;
			const oldIndex = this.moveElement.oldIndex;
			const element = this.moveElement.element;
			const prev = this.itemsManuallySorted[newIndex - 1];
			const next = this.itemsManuallySorted[newIndex + 1];

			if (
				(prev?._maxID !== element._maxID &&
					next?._maxID === prev?._maxID &&
					(!prev?._hasChilds?.length || this.treeStatus[prev?._maxID]?.includes(prev?.id))) ||
				(newIndex < oldIndex && !next?._parentID)
			) {
				this.updateItem(newIndex, element, next, -1);
				return;
			}

			if (
				(prev?._maxID !== element._maxID || next?._maxID !== element._maxID) &&
				newIndex > oldIndex &&
				!prev?._parentID
			) {
				this.updateItem(newIndex, element, prev, 1);
				return;
			}

			if (
				prev?._maxID !== element._maxID ||
				next?._maxID !== element._maxID ||
				(element._parentID === prev?._parentID &&
					prev?._depth === element?._depth &&
					element?._depth > next?._depth)
			) {
				return;
			}

			if (
				(element._parentID === prev?._parentID &&
					element._parentID !== next?._parentID &&
					prev?._depth > next?._depth) ||
				(this.treeStatus[prev?._maxID]?.includes(prev?.id) &&
					element._parentID === prev?._parentID &&
					element._parentID !== next?._parentID &&
					prev?._depth < next?._depth) ||
				(element._parentID !== prev?._parentID &&
					element._parentID !== next?._parentID &&
					prev?._parentID !== next?._parentID &&
					prev?._depth < next?._depth) ||
				(element._parentID !== prev?._parentID &&
					element._parentID !== next?._parentID &&
					prev?._parentID !== next?._parentID &&
					prev?._depth > next?._depth &&
					element._depth === next?._depth &&
					!this.treeStatus[prev?._maxID]?.includes(prev?.id))
			) {
				this.updateItem(newIndex, element, next, -1);
				return;
			}

			if (
				(element._parentID !== prev?._parentID && prev?._parentID === next?._parentID) ||
				(element._parentID !== prev?._parentID &&
					element._parentID !== next?._parentID &&
					prev?._parentID !== next?._parentID &&
					prev?._depth > next?._depth &&
					this.treeStatus[prev?._maxID]?.includes(prev?.id)) ||
				(newIndex > oldIndex && this.treeStatus[prev?._maxID]?.includes(prev?.id))
			) {
				this.updateItem(newIndex, element, prev, 1);
				return;
			}

			if (newIndex < oldIndex && this.treeStatus[prev?._maxID]?.includes(prev?._parentID)) {
				this.updateItem(newIndex, element, next, -1);
			}
		},
		updateItem(newIndex, element, item, num) {
			this.isUpdate = true;
			this.itemsManuallySorted[newIndex]._parentID = item?._parentID;
			this.itemsManuallySorted[newIndex]._depth = item?._depth;
			this.itemsManuallySorted[newIndex]._maxID = item?._maxID;
			this.itemsManuallySorted[newIndex][this.manualSortField] = item[this.manualSortField] + num;

			this.$emit('input', [
				{
					[this.primaryKeyField]: element[this.primaryKeyField],
					[this.parentField]: item?._parentID ?? null,
					[this.manualSortField]: Number(item[this.manualSortField] + num).toFixed(8),
				},
			]);
		},
		showDepth(row) {
			const item = this.treeStatus[row._maxID];

			if (
				!this.tree ||
				row._depth === 0 ||
				(item &&
					item.includes(row._maxID) &&
					(item.includes(row.id) || item.includes(row._parentID)))
			) {
				return true;
			}
			return false;
		},
		toggle(row) {
			this.$emit('pull-down', row);
		},
		/* 取得数字的精确位，正数表示n位小数，负数表示精确到个十百千万位（10的(n-1)次方） */
		getPrecisionOfNumber(num) {
			num = Math.abs(num);
			const len = num.toString().length;

			if (num.toString().indexOf('.') >= 0) {
				return len - num.toString().indexOf('.') + 1;
			}

			return 1;
		},
		round(number, precision) {
			return Math.round(+number + 'e' + precision) / Math.pow(10, precision);
		},
		/* 存在一个值的情况 */
		getCriticalValue(number, isAdd) {
			if (number.toString().indexOf('.') === -1) {
				return isAdd ? number + 0.1 : number - 0.1;
			}

			let decimal = this.getPrecisionOfNumber(number) - number.toString().indexOf('.') - 2;
			let offset = '0.0';

			decimal = decimal > 5 ? 5 : decimal;

			for (let i = 1; i < decimal; i++) {
				offset += '0';
			}

			const m = Math.pow(10, 8);

			return isAdd
				? (number * m + Number(offset + '1') * m) / m
				: (number * m - Number(offset + '1') * m) / m;
		},
		/* 取得两个数字的模糊中间数，并尽可能的忽略精确值。比如0.99与1.2的中间数是1 */
		getMiddleBetweenNumbers(bigNumber, smallNumber) {
			if (!bigNumber && !smallNumber) return null;

			if (!bigNumber) {
				return this.getCriticalValue(smallNumber);
			}

			if (!smallNumber) {
				return this.getCriticalValue(bigNumber, true);
			}

			if (bigNumber === smallNumber) {
				return bigNumber;
			}

			if (bigNumber < smallNumber) {
				return null;
			}

			const middle = smallNumber + (bigNumber - smallNumber) / 2;
			const precisionMin = Math.min(
				this.getPrecisionOfNumber(bigNumber),
				this.getPrecisionOfNumber(smallNumber),
				-1
			);
			const precisionMax = Math.max(
				this.getPrecisionOfNumber(bigNumber),
				this.getPrecisionOfNumber(smallNumber),
				precisionMin
			);

			for (let i = precisionMin; i <= precisionMax; i++) {
				const tmp = this.round(middle, i);
				if (tmp > smallNumber && tmp < bigNumber) {
					return tmp;
				}
			}

			return null;
		},
		/* 取得两个排序的中间排序值 */
		getRankValueBetweenItems(prevNum, nextNum) {
			if (!prevNum && !nextNum) return null;
			if (prevNum && !nextNum) return Number(prevNum) + 1;
			if (prevNum >= nextNum || !nextNum || !prevNum) {
				return this.getMiddleBetweenNumbers(prevNum, nextNum);
			}
			if (prevNum < nextNum) {
				return this.getMiddleBetweenNumbers(nextNum, prevNum);
			}
			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
.v-table {
	position: relative;
	width: 100%;
	padding-bottom: var(--page-padding-bottom);
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	&.loading {
		overflow: hidden; //Avoids scrollbars when initially loading items.
		.body {
			transition: opacity var(--medium) var(--transition-in);
			opacity: 0.4;
		}
	}
}

.toolbar,
.row {
	padding: 0 12px;
	display: flex;
	align-items: center;
	border-bottom: 2px solid var(--table-row-border-color);
	box-sizing: content-box;
}

.toolbar {
	position: sticky;
	height: var(--header-height);
	left: 0;
	top: 0;
	z-index: +1;
	background-color: var(--page-background-color);
	border-color: var(--table-head-border-color);
	transition: box-shadow var(--fast) var(--transition-out);

	&.shadow {
		box-shadow: var(--box-shadow);
		transition: box-shadow var(--medium) var(--transition-in);
	}
}

.body {
	position: relative;
	transition: opacity var(--medium) var(--transition-out);
	opacity: 1;
	height: calc(100% - var(--header-height));
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.drag-handle {
	width: 8px;
	height: 100%;
	cursor: col-resize;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 10px;
	opacity: 0;
	transition: opacity var(--fast) var(--transition-out);
}

.drag-handle-line {
	background-color: var(--input-border-color);
	width: 2px;
	height: 60%;
	transition: background-color var(--fast) var(--transition);
}

.drag-handle:hover .drag-handle-line {
	background-color: var(--input-border-color-hover);
}

.toolbar:hover .drag-handle {
	opacity: 1;
	transition: opacity var(--medium) var(--transition-in);
}

.row {
	opacity: 1;
	background-color: var(--page-background-color);
	box-sizing: border-box;
	min-height: 38px;
}

.row.link:hover {
	background-color: var(--highlight);
	cursor: pointer;
}

.dragging .row.link:hover {
	background-color: var(--page-background-color);
}

.row.selected {
	background-color: var(--highlight);
}

.cell {
	flex-shrink: 0;
	flex-basis: 200px;
	padding-right: 20px;
	position: relative;
	overflow: hidden;
	max-height: 100%;
}

.cell:last-of-type {
	flex-grow: 1;
}

.empty {
	color: var(--empty-value);
}

.toolbar .cell:not(.select) {
	height: 100%;
	display: flex;
	align-items: center;
}

// Table column header
.sort {
	width: 100%;
	height: 100%;
	text-align: left;
	transition: color var(--fast) var(--transition);
	position: relative;
}

.sort-icon {
	opacity: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin-left: 4px;
	&.asc {
		transform-origin: center 6px;
		transform: scaleY(-1);
	}
}

.active .sort-icon {
	opacity: 1;
}

.select,
.manual-sort {
	flex-basis: 30px;
	padding: 0;
	margin-left: -3px; /* Shift to accomodate material design icons checkbox */
	margin-right: 8px;
}

.toolbar .manual-sort {
	button {
		color: var(--input-border-color);
		transition: color var(--fast) var(--transition);

		&:hover {
			transition: none;
			color: var(--input-border-color-hover);
		}
	}

	&.active button {
		color: var(--input-border-color-focus);
	}
}

.body .manual-sort {
	cursor: not-allowed;
	color: var(--input-background-color-disabled);

	&.active {
		cursor: grab;
		cursor: -webkit-grab;
		color: var(--input-border-color);
	}
}

.sortable-drag {
	opacity: 0;
}

.dragging .sortable-chosen,
.sortable-chosen:active {
	background-color: var(--highlight) !important;
	color: var(--blue-grey-900);

	.manual-sort {
		color: var(--blue-grey-700);
	}
}

.loader {
	div {
		animation: bounce 1s var(--transition) infinite alternate;
	}

	$elements: 50;
	@for $i from 0 to $elements {
		div:nth-child(#{$i + 1}) {
			animation-delay: $i * 100ms;
		}
	}
}

@keyframes bounce {
	from {
		border-color: var(--table-row-border-color);
	}

	to {
		border-color: var(--table-head-border-color);
	}
}

.lazy-loader {
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transform: translateY(50px);
}

.fade-enter-active {
	transition: var(--slow) var(--transition-in);
}

.fade-leave-active {
	transition: var(--slow) var(--transition-out);
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tree {
	display: flex;
	align-items: center;
}

.down-icon {
	border-radius: 50%;
	transition: 0.2s;
	margin-right: 4px;
	&:hover {
		background-color: var(--sidebar-background-color-alt);
	}
	&-active {
		transform: rotate(90deg);
	}
}

.pl-8 {
	padding-left: 34px;
}
</style>
