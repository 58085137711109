import formatFilters from './format-filters';
import isRelational from './is-relational';
const relationTypes = ['o2m', 'm2o'];

/**
 * 组装查询参数，补齐所需参数
 *
 * @param depth 	抓取深度，使用 tree view 时使用，默认为 null
 * @param filter	覆盖默认的 filter
 *
 */
export default function formatParams(context, depth = null, filters = null) {
	const params = {
		meta: 'total_count,result_count,filter_count',
		limit: context.$store.state.settings.values.default_limit,
		offset: context.$store.state.settings.values.default_limit * (context.items.page ?? 0),
		...(context.viewQuery ?? {}),
	};

	if (context?.abortController?.signal) {
		Object.assign(params, {
			_signal: context.abortController.signal
		});
	}

	const availableFields = Object.keys(context.fields);

	if (!params.fields) {
		params.fields = availableFields;
	}

	if (params.fields instanceof Array == false) {
		params.fields = params.fields.split(',');
	}

	// Make sure we don't try to fetch non-existing fields
	params.fields = params.fields.filter(field => {
		return availableFields.includes(field);
	});

	// make sure file related type have file info
	// params.fields = params.fields.map(field => {
	// 	const fieldInterface = context.$store.state.collections[context.collection].fields[field].interface;
	// 	return fileInterfaces.includes(fieldInterface) ? `${field}.*` : field;
	// });

	// Make sure to always fetch the primary key. This is needed to generate the links to the
	// detail pages
	if (!params.fields.includes(context.primaryKeyField)) {
		params.fields.push(context.primaryKeyField);
	}

	// Make sure to always fetch the status values. These are needed for permissions.
	if (context.statusField && !params.fields.includes(context.primaryKeyField)) {
		params.fields.push(context.statusField);
	}

	// Make sure to always fetch sort field values, these are needed for sort.
	if (context.sortField && !params.fields.includes(context.sortField)) {
		params.fields.push(context.sortField);
	}

	// Make sure fetch values of fields needed for tree view
	if (context.viewType === 'tree') {
		const parentField = context.viewOptions.parent || 'parent';
		if (!params.fields.includes(parentField)) {
			params.fields.push(parentField);
		}

		const childrenField =
			context.viewOptions.childs ||
			(availableFields.includes('children') ? 'children' : 'childs');
		if (!params.fields.includes(childrenField)) {
			params.fields.push(childrenField);
		}

		params.limit = -1;

		Object.assign(context.viewOptions, { parent: parentField, childs: childrenField });
	}

	params.fields = params.fields.map(fieldName => {
        const field = context.fields[fieldName];

        if (!isRelational(field)) return fieldName;

		const fieldType = field.type.toLowerCase();

        if (fieldType === 'o2m') {
            const relation = context.$store.getters.o2m(context.collection, field);
            if (relation?.junction_field) {
                return `${fieldName}.${relation.junction_field}.*`;
            }
        }

        return `${fieldName}.*`;
	});

	/* For non-admin users if created_at and status field is available in
	 * collection fetch it from API even if it is set hidden from info sidebar.
	 * Because for checking role_only and mine permissions while batch updating
	 * or deleting data these fields are required.
	 * Fix 2123
	 */
	if (!context.$store.state.currentUser.admin) {
		if (
			context.userCreatedField !== undefined &&
			!params.fields.includes(`${context.userCreatedField}.*`)
		) {
			params.fields.push(`${context.userCreatedField}.*`);
		}
		if (context.statusField !== null && !params.fields.includes(`${context.statusField}.*`)) {
			params.fields.push(`${context.statusField}.*`);
		}
	}

	// for tree view, we need fetch current level's childs's childs
	if (context.viewType === 'tree' && depth !== null) {
		const prefixs = [];
		const chainFields = params.fields.filter(field => {
			const f = field.split('.')[0];
			return (
				f !== context.viewOptions.childs &&
				relationTypes.includes(context.fields[f].type.toLowerCase())
			);
		});
		for (let d = 0; d <= depth; d++) {
			prefixs.push(context.viewOptions.childs);
			chainFields.forEach(field => {
				params.fields.push(`${prefixs.join('.')}.${field}`);
			});
			params.fields.push(`${prefixs.join('.')}.${context.viewOptions.childs}.*`);
		}
	}

	params.fields = params.fields.join(',');

	if (params.sort) {
		const sortFields = params.sort.split(',');

		params.sort = sortFields
			.filter(field => {
				if (field.startsWith('-')) {
					field = field.substring(1);
				}

				return availableFields.includes(field);
			})
			.join(',');

		if (params.sort.length === 0) delete params.sort;
	}

	if (context.collection === 'directus_files' && !params.sort) {
		params.sort = '-uploaded_on';
	}

	if (context.searchQuery) {
		params.q = context.searchQuery;
	}

	// filters
	if (context.filters && context.filters.length > 0) {
		Object.assign(params, {
			...formatFilters(context.filters)
		});
	}

	if (context.showSoftDelete) {
		params.status = '*';
	}

	if (context.viewType === 'tree' && depth > 0) {
		Object.assign(params, {
			...formatFilters([
				{
					field: context.viewOptions.parent,
					operator: 'empty',
					value: null
				}
			])
		});
	}

	if (filters instanceof Array && filters.length > 0) {
		Object.assign(params, {
			...formatFilters(filters)
		});
	}

	return params;
}
