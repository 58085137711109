import store from './store/';
import { loadLanguageAsync } from './lang/';
import { STORE_HYDRATED, HYDRATING_FAILED } from './store/mutation-types';

export default async function hydrateStore() {
	try {
		await store.dispatch('getCurrentUser');

        // getAllExtensions action will translate some values. We have to make sure to fetch the locales
        // before fetching the extensions
        const userLocale = store.state.currentUser?.locale;
        const defaultLocale = store.getters.currentProject?.data?.default_locale;
        const browserLocale = window.navigator.userLanguage || window.navigator.language;
        const locale = userLocale ?? (defaultLocale ?? browserLocale);
        await loadLanguageAsync(locale);

		await Promise.all([
			store.dispatch('latency'),
			store.dispatch('getAllExtensions'),
			store.dispatch('getCollections').then(() => store.dispatch('getPermissions')),
			store.dispatch('getSettings'),
			store.dispatch('getBookmarks'),
			store.dispatch('getUsers'),
			store.dispatch('getRelations'),
			store.dispatch('getServerInfo')
		]);

		store.commit(STORE_HYDRATED, new Date());
	} catch (error) {
		store.commit(HYDRATING_FAILED, error);
		console.error(error); // eslint-disable-line no-console
	}
}
