<template>
	<portal to="modal">
		<v-modal :title="$t('select_existing')" :buttons="{
			done: {
				text: $t('done'),
				color: 'accent',
			},
		}" @done="$emit('done')" @close="$emit('cancel')">
			<div class="search-sort">
				<input class="search-sort__left" :placeholder="$t('search')" :value="searchQuery" type="search"
					@input="setSearchQuery" />

				<div class="search-sort__right" v-if="Object.keys(sortOptions).length">
					<div class="search-sort__order">
						<label>{{ $t('sort_by') }}</label>
						<v-select v-model="sortField" :clearable="false" :options="sortOptions" />
						<v-button icon background-color="transparent" hoverBackgroundColor="transparent"
							@click="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'">
							<v-icon name="sort" color="--input-icon-color"
								:style="{ rotate: sortDirection === 'asc' ? '180deg' : '0deg' }" />
						</v-button>
					</div>
					<v-button class="search-sort__thumbnail" icon background-color="transparent"
						hoverBackgroundColor="transparent" @click="isThumbnail = !isThumbnail" v-if="isDirectusFiles">
						<v-icon :name="isThumbnail ? 'view_module' : 'view_list'" color="--input-icon-color" />
					</v-button>
				</div>
			</div>

			<div v-if="hydrating" class="spinner">
				<v-spinner />
			</div>

			<template v-else-if="items.length > 0">
				<v-cards v-if="isDirectusFiles && isThumbnail" class="items-cards" :style="{ opacity: loading ? 0.3 : 1 }"
					:fields="cardsFields" :items="items" :collection="collection" :view-options="viewOptions"
					:primaryKeyField="primaryKeyField" :selection="value" @select="updateSelect" />

				<div v-else class="items" :class="{ loading }">
					<div class="head">
						<!-- Checkboxes -->
						<span>
							<v-icon v-if="!single" @click="toggleAll()" :name="(value || []).length == 0
								? 'check_box_outline_blank'
								: value.length == items.length
									? 'check_box'
									: 'indeterminate_check_box'
								" :color="(value || []).length == items.length
		? '--input-background-color-active'
		: '--input-border-color'
		" />
						</span>
						<span v-if="isDirectusFiles">{{ $t('file') }}</span>
						<span v-for="field in fields" :key="field">
							{{ $helpers.formatField(field, collection) }}
						</span>
					</div>

					<label v-for="item in items" :key="uid + '_' + item[primaryKeyField]">
						<div class="input">
							<input :type="single ? 'radio' : 'checkbox'" :name="uid" :value="item[primaryKeyField]"
								:checked="isChecked(item[primaryKeyField])" @change="updateValue(item[primaryKeyField])" />
							<v-icon v-if="single" :name="isChecked(item[primaryKeyField])
								? 'radio_button_checked'
								: 'radio_button_unchecked'
								" :color="isChecked(item[primaryKeyField])
		? '--input-background-color-active'
		: '--input-border-color'
		" />
							<v-icon v-else
								:name="isChecked(item[primaryKeyField]) ? 'check_box' : 'check_box_outline_blank'" :color="isChecked(item[primaryKeyField])
									? '--input-background-color-active'
									: '--input-border-color'
									" />
						</div>

						<span v-if="isDirectusFiles">
							<v-ext-display interface-type="file" name="thumbnail" collection="directus_files" type="JSON"
								datatype="TEXT" :value="item" />
						</span>

						<span v-for="fieldInfo in fieldsWithInfo" :key="uid + '_' + fieldInfo.field">
							<v-ext-display :id="uid + '_' + fieldInfo.field" :interface-type="fieldInfo.interface"
								:name="uid + '_' + fieldInfo.field" :type="fieldInfo.type" :datatype="fieldInfo.datatype"
								:options="fieldInfo.options" :value="item[fieldInfo.field]"
								:values="getItemValueById(item.id)" />
						</span>
					</label>
				</div>

				<v-button v-if="moreItemsAvailable" class="more" :loading="loading" @click="loadMore">
					{{ $t('load_more') }}
				</v-button>
			</template>

			<div v-else-if="!loading" class="not-found" :class="{ loading }">
				{{ $t('no_results') }}
			</div>
		</v-modal>
	</portal>
</template>

<script>
import VCards from '@/layouts/cards/layout.vue';
import formatFilters from '@/helpers/format-filters';
import shortid from 'shortid';
import getFieldsFromTemplate from '@/helpers/get-fields-from-template';
import { find, isEqual, debounce, merge, clone, forEach, mapValues } from 'lodash';

export default {
	name: 'ItemSelect',

	components: {
		VCards,
	},

	props: {
		// The collection to fetch the items from. Has to be a string, eg `movies`
		collection: {
			type: String,
			required: true,
		},

		// Array of fields to display in the table
		fields: {
			type: Array,
			required: true,
		},

		// Array of filter objects in the app filter syntax. Allows the admin to setup what items to
		// show, eg
		//
		// [
		//   {
		//     "field": "title",
		//     "operator": "contains",
		//     "value": "directus"
		//   }
		// ]
		filters: {
			type: Array,
			default: () => [],
		},

		// The current selection. In case of the single-selection mode this is a primary key, otherwise
		// it's an array of primary keys
		value: {
			type: [Array, String, Number],
			default: null,
		},

		// Use single mode. This will use radio buttons instead of checkboxes so the user can only select
		// a single item
		single: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// The raw items fetched from the collection based on the filtes and fields that are requested
			items: [],

			// Total number of items in this collection
			totalCount: 0,

			// If the items are currently being loaded (fresh)
			loading: false,

			// Populated if something went wrong during the fetching of the items
			error: false,

			searchQuery: '',
			sortField: null,
			sortDirection: 'desc',

			// First load in progress
			hydrating: true,

			// If the length of the last result is less than the limit, we know that there aren't any more
			// items to load.
			moreItemsAvailable: false,
			isThumbnail: true,
			viewOptions: {
				content: 'description',
				src: 'data',
				subtitle: 'type',
				title: 'title',
			},
		};
	},
	computed: {
		// The primary key field of the collection of which we're fetching items
		primaryKeyField() {
			const collection = this.$store.state.collections[this.collection];
			if (!collection) return null;
			return find(collection.fields, { primary_key: true }).field;
		},

		// Unique ID for this interface. Will be used in the name field of the inputs
		uid() {
			return shortid.generate();
		},

		// The fields array from props augmented with the fields information from directus_collections
		// Allows us to render the value in the display component of the interface that's setup for this
		// field
		fieldsWithInfo() {
			return this.fields.map((fieldName) => {
				return this.$store.state.collections[this.collection].fields[fieldName];
			});
		},

		cardsFields() {
			const fields = this.$store.state.collections[this.collection].fields;
			const sortedValues = Object.values(fields).sort((a, b) => (a.sort < b.sort ? -1 : 1));
			const sortedFields = {};
			for (let field of sortedValues) {
				sortedFields[field.field] = field;
			}
			return (
				mapValues(sortedFields, (field) => ({
					...field,
					name: this.$helpers.formatField(field.field, field.collection),
				})) || {}
			);
		},

		sortOptions() {
			const options = {};

			let fields = this.fieldsWithInfo
				.filter(field => !!field.datatype)
				.map((field) => field.field);

			if (this.isDirectusFiles && !this.fields.includes('uploaded_on')) {
				fields = ['uploaded_on', ...this.fields];
			}

			fields?.forEach((field) => {
				options[field] = this.$helpers.formatField(field, this.collection);
			});

			return options;
		},
		isDirectusFiles() {
			return this.collection === 'directus_files';
		},
	},

	// Re-fetch the items whenever the collection / filters prop changes
	watch: {
		collection() {
			this.fetchItems();
		},
		filters: {
			deep: true,
			handler(before, after) {
				if (!isEqual(before, after)) this.fetchItems();
			},
		},
		sortField(newVal, oldVal) {
			// ignore when initial sortField
			if (oldVal === null
				&& newVal !== oldVal
				&& this.items.length === 0) {
				return
			}
			this.fetchItems();
		},
		sortDirection() {
			this.fetchItems();
		},
	},

	// Fetch the items on first load of the interface
	created() {
		this.sortField = Object.keys(this.sortOptions)[0];

		this.fetchItems();

		// Fetch the total number of items in this collection, so we can accurately render the load more
		// button
		this.$api
			.getItems(this.collection, {
				meta: 'total_count',
				limit: 1,
			})
			.then((res) => res.meta)
			.then((meta) => (this.totalCount = meta.total_count))
			.catch((error) => (this.error = error));
	},

	methods: {
		// Fetch the items based on the passed collection, filters, and fields prop
		fetchItems: debounce(function (options = {}) {
			const defaultOptions = {
				replace: true,
				offset: 0,
			};

			options = merge(defaultOptions, options);

			this.loading = true;
			this.error = null;

			const params = {
				limit: this.$store.state.settings.values.default_limit,
				offset: options.offset,
				meta: '*',
				fields: [], // ISSUE#1865 Fixed Define the blank fields array to push the data.
			};

			if (this.searchQuery.length > 0) {
				params.q = this.searchQuery;
			}

			if (this.filters.length > 0) {
				Object.assign(params, formatFilters(this.filters));
			}

			if (this.isDirectusFiles) {
				params.fields = ['*'];
			} else if (this.fields.length > 0) {
				params.fields = clone(this.fields);
			}

			params.fields = params.fields.map((f) => `${f}.*`);

			// we should add visible_fields to fields
			const visibleFields = this.fieldsWithInfo.reduce(
				(result, field) => {
					if (field.options?.visible_fields) {
						const visibleFields = field.options.visible_fields.split(',');
						visibleFields.forEach((visibleField) => {
							result.push(`${field.field}.${visibleField.trim()}`)
						})
					};
					return result;
				},
				[]
			);
			params.fields = params.fields.concat(visibleFields);

			if (this.sortField) {
				params.sort = this.sortDirection === 'desc' ? `-${this.sortField}` : this.sortField;
			}

			// No matter what, always fetch the primary key as that's used for the selection
			params.fields.push(this.primaryKeyField);

			// ISSUE#1993 Preview Field URL Doesn't Contain Variable in List
			const fieldsData = this.fields.map((fieldName) => {
				return this.$store.state.collections[this.collection].fields[fieldName];
			});
			const aliasFields = Object.values(fieldsData).filter(
				(field) => typeof field != 'undefined' && field.type.toLowerCase() === 'alias'
			);
			if (aliasFields.length > 0) {
				forEach(aliasFields, function (value) {
					if (value.options.url_template.match(/{{(.*)}}/g)) {
						const templateFields = getFieldsFromTemplate(value.options.url_template)[0];
						const field = templateFields.split('.')[0];
						if (!params.fields.includes(`${field}.*`) && !params.fields.includes(field)) {
							params.fields.push(`${field}.*`);
						}
					}
				});
			}

			this.$api
				.getItems(this.collection, params)
				.then((res) => {
					this.moreItemsAvailable = res.meta.limit === res.meta.result_count;
					return res.data;
				})
				.then((items) => {
					this.error = null;
					if (options.replace) return (this.items = items);
					return (this.items = [...this.items, ...items]);
				})
				.catch((error) => {
					if (!this.isCancel) this.error = error;
					this.isCancel = false;
				})
				.finally(() => {
					this.loading = false;
					this.hydrating = false;
					this.isCancel = false;
				});
		}, 500),

		// Select/Deselect all values and stage them to the parent component
		toggleAll() {
			if (this.value && this.value.length == this.items.length) {
				this.$emit('input', []);
			} else {
				this.$emit(
					'input',
					this.items.map((item) => item[this.primaryKeyField])
				);
			}
		},

		// Stage the value to the parent component
		updateValue(primaryKey) {
			if (this.single) {
				return this.$emit('input', primaryKey);
			}

			if (this.value && this.value.includes(primaryKey)) {
				this.$emit(
					'input',
					this.value.filter((pk) => pk !== primaryKey)
				);
			} else {
				this.$emit('input', [...(this.value || []), primaryKey]);
			}
		},

		updateSelect(newSelection) {
			this.$emit('input', newSelection);
		},

		// Check if the provided primaryKey is included in the selection
		isChecked(primaryKey) {
			if (this.single) {
				// non-strict comparison. It might happen that the numeric id 1 is returned as '1' by the api
				return this.value == primaryKey;
			}

			return this.value && this.value.includes(primaryKey);
		},

		// Set the search query
		setSearchQuery: function (event) {
			this.searchQuery = event.target.value;
			this.loading = true;
			this.fetchItems();
		},

		// Request more items from the server and append to the end of the list
		loadMore() {
			const offset = this.items.length;
			this.fetchItems({
				offset: offset,
				replace: false,
			});
		},
		getItemValueById(id) {
			const value = this.items.filter((item) => item.id == id)[0];
			return Object.assign({}, value);
		},
	},
};
</script>

<style lang="scss" scoped>
.items {
	display: table;
	min-width: 100%;
	padding: 0 32px;
	margin-bottom: 32px;
}

.not-found,
.items-cards,
.items {
	transition: opacity 0.3s;

	&.loading {
		opacity: 0.3;
		cursor: progress;
		pointer-events: none;
	}
}

.items label:hover {
	background-color: var(--highlight);
	cursor: pointer;
}

.items label,
.items .head {
	display: table-row;
}

.layout-cards {
	max-height: 100% !important;
}

.head {
	position: sticky;
	display: block;
	top: 0px;
	font-weight: 500;
	background-color: var(--page-background-color);
}

.items .head>* {
	display: table-cell;
	border-bottom: 2px solid var(--table-head-border-color);
	padding: 12px 32px 12px 0;
}

.items label>* {
	display: table-cell;
	vertical-align: middle;
	border-bottom: 2px solid var(--table-row-border-color);
	padding: 8px 32px 8px 0;
}

.items label>*:first-child,
.items .head>*:first-child {
	max-width: 40px;
	padding: 8px 8px 8px 0;
	width: 40px;
}

.items label>*:nth-child(2) {
	min-width: 64px;
}

.items label>*:nth-child(3) {
	max-width: 220px;
}

.input input,
.search-sort input[type='checkbox'] {
	position: absolute;
	left: -9999px;
	opacity: 0;
	visibility: hidden;
}

.spinner {
	padding: 80px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-sort {
	display: flex;
	border-bottom: 2px solid var(--modal-header-background-color);
	padding: 8px 0;
	padding-right: 32px;
	align-items: center;

	&__left {
		flex-grow: 1;
		font-size: 1rem;
		border: none;
		border-radius: 0;
		padding: 8px 0 8px 32px;
		appearance: none;
		background-color: var(--page-background-color);

		&::placeholder {
			color: var(--input-placeholder-color);
		}
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__order {
		display: flex;
		align-items: center;

		label {
			display: inline-block;
			white-space: nowrap;
			padding: var(--input-padding);
		}

		:deep(.v-select) {
			min-width: 7em;
		}
	}
}

.more {
	display: block;
	margin: 32px auto;
}

.not-found {
	padding: 32px;
}
</style>
