var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-switcher"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
			content: _vm.tooltipContent,
			boundariesElement: 'body'
		}),expression:"{\n\t\t\tcontent: tooltipContent,\n\t\t\tboundariesElement: 'body'\n\t\t}",modifiers:{"left":true}}],staticClass:"content",class:{
			slow: _vm.signalStrength === 1,
			disconnected: _vm.signalStrength === 0
		}},[_c('v-signal',{staticClass:"icon"}),_c('span',{staticClass:"no-wrap project-name"},[(_vm.currentProject.status === 'successful')?[_vm._v(" "+_vm._s(_vm.currentProject.data.project_name)+" ")]:[_vm._v(" "+_vm._s(_vm.currentProjectKey)+" ")]],2),(_vm.projects.length > 1)?_c('v-icon',{staticClass:"chevron",attrs:{"name":"expand_more"}}):_vm._e(),(_vm.projects.length > 1)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentProjectKey),expression:"currentProjectKey"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentProjectKey=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.projects),function(project){return _c('option',{key:project.key,attrs:{"name":project.key},domProps:{"value":project.key,"selected":_vm.currentProjectKey === project.key}},[(project.status === 'successful')?[_vm._v(" "+_vm._s(project.data.project_name)+" "),(project.data.authenticated === true)?[_vm._v(" • ")]:_vm._e()]:[_vm._v(" "+_vm._s(project.key)+" ")]],2)}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }