import { onMounted, onUnmounted, ref } from 'vue';
import { formatDistance } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import dateHelper from '@/helpers/date';

export default function useFormatDistance(
	date: Date | number,
	timezone: string | null = null,
	isDate: boolean = false,
	autoUpdate: number = 60000
) {
	if (!timezone) timezone = dateHelper.getLocaleTimezone();

	const formatFunc = () => {
		const current = new Date();
		let baseDate: Date = current;
		if (isDate) {
			baseDate = new Date(
				Date.UTC(current.getFullYear(), current.getMonth(), current.getDate())
			);
		}
		return formatDistance(date, baseDate, {
			addSuffix: true
		});
	};

	const formattedDate = ref(formatFunc());

	let interval: number;
	if (autoUpdate !== 0) {
		onMounted(() => {
			interval = setInterval(() => {
				formattedDate.value = formatFunc();
			}, autoUpdate);
		});

		onUnmounted(() => {
			clearInterval(interval);
		});
	}

	return formattedDate;
}
