<template>
	<v-spinner :size="20" color="--input-border-color-hover" />
</template>

<script>
export default {
	name: 'VExtDisplayLoading'
};
</script>

<style scoped>
.v-spinner {
	max-width: 20px;
	max-height: 20px;
	min-width: 20px;
	min-height: 20px;
}
</style>
