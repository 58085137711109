<template>
	<div class="v-layout-loading">
		<v-spinner color="--blue-grey-300" background-color="--blue-grey-200" class="spinner" />
	</div>
</template>

<script>
export default {
	name: 'VLayoutLoading'
};
</script>
